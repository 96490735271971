import { c as AUDIT_RECORD_STATUS_TEXTS, d as AUDIT_RECORD_STATUS_COLORS, e as AUDIT_CYCLE_STATUS_COLORS, f as AUDIT_CYCLE_STATUS_TEXTS, a as AUDIT_STATUS_COLORS, b as AUDIT_STATUS_TEXTS } from "./audit.status.js";
import { D as DATE_FORMAT, a as DATE_TIME_FORMAT } from "./audit.others.js";
import { MAINTAIN_PERMISSION, STERILIZATION_AUDITS_AUDITGROUP } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$9 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "table-list-audit-history pt-3 px-5" }, [_c("a-table", { attrs: { "bordered": "", "columns": _vm.columns, "data-source": _vm.dataHistory, "row-key": "id" }, on: { "change": _vm.onTableChange }, scopedSlots: _vm._u([{ key: "itemCode", fn: function(itemCode) {
    return [_vm._v(_vm._s(itemCode))];
  } }, { key: "auditDate", fn: function(auditDate) {
    return [_vm._v(_vm._s(_vm._f("moment")(auditDate, _vm.DATE_FORMAT)))];
  } }, { key: "status", fn: function(status) {
    return [status !== void 0 && status !== null ? [_c("tag-field", { attrs: { "value": _vm.AUDIT_RECORD_STATUS_TEXTS[status], "color": _vm.AUDIT_RECORD_STATUS_COLORS[status] } })] : _vm._e()];
  } }, { key: "action", fn: function(record) {
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.goToDetail(record);
    } } })], 1) : _vm._e()];
  } }]) })], 1);
};
var staticRenderFns$9 = [];
var ListAuditHistoryOfCycle_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".table-list-audit-history[data-v-4fafc972] .ant-table-tbody>tr>td{border:1px solid #cdd3db!important}\n")();
const __vue2_script$9 = {
  name: "ListAuditHistoryOfCycle",
  inject: ["historyApiUrl", "crud", "can"],
  data() {
    return {
      AUDIT_RECORD_STATUS_TEXTS,
      AUDIT_RECORD_STATUS_COLORS,
      DATE_FORMAT,
      dataHistory: [],
      permissions: {
        maintain: MAINTAIN_PERMISSION
      },
      columns: [
        {
          title: "Item",
          dataIndex: "itemCode",
          key: "ItemCode",
          scopedSlots: { customRender: "itemCode" },
          sorter: true
        },
        {
          title: "Audit Date",
          dataIndex: "auditDate",
          key: "AuditDate",
          scopedSlots: { customRender: "auditDate" },
          sorter: true
        },
        {
          title: "Process History",
          dataIndex: "processHistory",
          key: "ProcessHistory",
          sorter: true
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "Status",
          scopedSlots: { customRender: "status" },
          sorter: true
        },
        {
          title: "No Of Measurement",
          dataIndex: "noOfMeasurement",
          key: "NoOfMeasurement",
          align: "right",
          sorter: true
        },
        {
          title: "TP Contact",
          dataIndex: "tpContact",
          key: "TPContact",
          sorter: true
        },
        {
          title: "PD Contact",
          dataIndex: "pdContact",
          key: "PDContact",
          sorter: true
        },
        {
          title: "Filling Line",
          dataIndex: "fillingLine",
          key: "FillingLine",
          sorter: true
        },
        {
          title: "Steriliser",
          dataIndex: "steriliser",
          key: "Steriliser",
          sorter: true
        },
        {
          title: "Action",
          key: "action",
          sorter: false,
          align: "center",
          scopedSlots: {
            customRender: "action"
          }
        }
      ]
    };
  },
  async created() {
    const res = await this.fetchList();
    this.dataHistory = res.data;
  },
  methods: {
    async fetchList() {
      return await this.crud.fetchList(this.historyApiUrl);
    },
    onTableChange(pagination, filters, sorter) {
      const { current, pageSize } = pagination;
      this.crud.setPagination({
        ...this.crud.getPagination(),
        page: current,
        pageSize
      });
      const { columnKey, order } = sorter;
      this.crud.setSorter({ columnKey, order });
      this.fetchList();
    },
    goToDetail(record) {
      const groupId = this.$route.params.id;
      const { id, itemId } = record;
      this.$router.push({
        name: "EditAuditRecord",
        params: {
          recordId: id
        },
        query: {
          groupId,
          itemId
        }
      });
    }
  }
};
const __cssModules$9 = {};
var __component__$9 = /* @__PURE__ */ normalizeComponent(__vue2_script$9, render$9, staticRenderFns$9, false, __vue2_injectStyles$9, "4fafc972", null, null);
function __vue2_injectStyles$9(context) {
  for (let o in __cssModules$9) {
    this[o] = __cssModules$9[o];
  }
}
var ListAuditHistoryOfCycle$1 = /* @__PURE__ */ function() {
  return __component__$9.exports;
}();
var render$8 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-audit-history" }, [_c("resource", { attrs: { "name": "sterilization.audit-groups.cycles.histories", "api-url": _vm.apiUrl, "list": _vm.ListAuditHistoryOfCycle, "page": _vm.page } }), _c("router-view")], 1);
};
var staticRenderFns$8 = [];
const apiUrl$5 = "#{VUE_APP_API_URL}#";
const __vue2_script$8 = {
  name: "ListAuditHistoryOfCycleIndex",
  props: {
    groupId: {
      type: [String, Number],
      required: true
    },
    cycleId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      ListAuditHistoryOfCycle: ListAuditHistoryOfCycle$1,
      apiUrl: apiUrl$5,
      page: STERILIZATION_AUDITS_AUDITGROUP
    };
  },
  provide() {
    return {
      historyApiUrl: `${"#{VUE_APP_API_URL}#"}/sterilization/audit-groups/${this.groupId}/cycles/${this.cycleId}/histories`
    };
  }
};
const __cssModules$8 = {};
var __component__$8 = /* @__PURE__ */ normalizeComponent(__vue2_script$8, render$8, staticRenderFns$8, false, __vue2_injectStyles$8, null, null, null);
function __vue2_injectStyles$8(context) {
  for (let o in __cssModules$8) {
    this[o] = __cssModules$8[o];
  }
}
var ListAuditHistoryOfCycle = /* @__PURE__ */ function() {
  return __component__$8.exports;
}();
var render$7 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "table-audit-cycle py-3 px-5" }, [_c("a-table", { attrs: { "data-source": _vm.data, "bordered": "", "row-key": "id" }, on: { "change": _vm.onTableChange }, scopedSlots: _vm._u([{ key: "expandedRowRender", fn: function(record) {
    return [_c("list-audit-history-of-cycle", { attrs: { "group-id": _vm.auditGroupId, "cycle-id": record.id } })];
  } }]) }, [_c("a-table-column", { key: "AuditCycleNo", attrs: { "data-index": "auditCycleNo", "title": "Audit Cycle", "sorter": true }, scopedSlots: _vm._u([{ key: "default", fn: function(auditCycleNo) {
    return [_c("a", [_vm._v(_vm._s(auditCycleNo))])];
  } }]) }), _c("a-table-column", { key: "Deadline", attrs: { "data-index": "deadline", "title": "Audit Due Date", "sorter": true }, scopedSlots: _vm._u([{ key: "default", fn: function(deadline) {
    return [_c("span", [_vm._v(_vm._s(_vm._f("moment")(deadline, _vm.DATE_FORMAT)))])];
  } }]) }), _c("a-table-column", { key: "NoOfAudit", attrs: { "data-index": "noOfAudit", "title": "No of Audits", "align": "right", "sorter": true } }), _c("a-table-column", { key: "Status", attrs: { "data-index": "status", "title": "Status", "sorter": true }, scopedSlots: _vm._u([{ key: "default", fn: function(status) {
    return [status !== void 0 && status !== null ? [_c("tag-field", { attrs: { "color": _vm.AUDIT_CYCLE_STATUS_COLORS[status], "value": _vm.AUDIT_CYCLE_STATUS_TEXTS[status] } })] : _vm._e()];
  } }]) })], 1)], 1);
};
var staticRenderFns$7 = [];
const __vue2_script$7 = {
  name: "ListAuditCycle",
  components: {
    ListAuditHistoryOfCycle
  },
  inject: ["auditGroupId", "cycleApiUrl", "crud"],
  data() {
    return {
      AUDIT_CYCLE_STATUS_COLORS,
      AUDIT_CYCLE_STATUS_TEXTS,
      DATE_FORMAT,
      data: []
    };
  },
  async created() {
    const res = await this.fetchList();
    this.data = res.data;
  },
  methods: {
    async fetchList() {
      return await this.crud.fetchList(this.cycleApiUrl);
    },
    parseStatus(value) {
      return AUDIT_CYCLE_STATUS_TEXTS[value];
    },
    onTableChange(pagination, filters, sorter) {
      const { current, pageSize } = pagination;
      this.crud.setPagination({
        ...this.crud.getPagination(),
        page: current,
        pageSize
      });
      const { columnKey, order } = sorter;
      this.crud.setSorter({ columnKey, order });
      this.fetchList();
    }
  }
};
const __cssModules$7 = {};
var __component__$7 = /* @__PURE__ */ normalizeComponent(__vue2_script$7, render$7, staticRenderFns$7, false, __vue2_injectStyles$7, null, null, null);
function __vue2_injectStyles$7(context) {
  for (let o in __cssModules$7) {
    this[o] = __cssModules$7[o];
  }
}
var ListAuditCycle$1 = /* @__PURE__ */ function() {
  return __component__$7.exports;
}();
var render$6 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-audit-cycle" }, [_c("resource", { attrs: { "name": "sterilization.audit-groups.cycles", "api-url": _vm.apiUrl, "list": _vm.ListAuditCycle, "redirect-route": "/sterilization-audits/audit-groups" } }), _c("router-view")], 1);
};
var staticRenderFns$6 = [];
const apiUrl$4 = "#{VUE_APP_API_URL}#";
const __vue2_script$6 = {
  name: "AuditCycle",
  props: {
    groupId: {
      type: [String, Number],
      required: true
    }
  },
  provide() {
    return {
      auditGroupId: this.groupId,
      cycleApiUrl: `${"#{VUE_APP_API_URL}#"}/sterilization/audit-groups/${this.groupId}/cycles`
    };
  },
  data() {
    return {
      ListAuditCycle: ListAuditCycle$1,
      apiUrl: apiUrl$4
    };
  }
};
const __cssModules$6 = {};
var __component__$6 = /* @__PURE__ */ normalizeComponent(__vue2_script$6, render$6, staticRenderFns$6, false, __vue2_injectStyles$6, null, null, null);
function __vue2_injectStyles$6(context) {
  for (let o in __cssModules$6) {
    this[o] = __cssModules$6[o];
  }
}
var ListAuditCycle = /* @__PURE__ */ function() {
  return __component__$6.exports;
}();
var render$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "information-group" }, [_c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-card", { scopedSlots: _vm._u([_vm.can(_vm.permissions.maintain) ? { key: "actions", fn: function() {
      return [_c("div", { staticClass: "px-4" }, [_c("a-row", { attrs: { "type": "flex", "justify": "end", "gutter": 8 } }, [_c("a-col", [_c("a-button", { on: { "click": _vm.onCancel } }, [_vm._v("Cancel")])], 1), _c("a-col", [_c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.onSave);
      } } }, [_vm._v("Save")])], 1)], 1)], 1)];
    }, proxy: true } : null], null, true) }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "xs": 24, "lg": 12 } }, [_c("text-input", { attrs: { "value": _vm.entity.groupName, "label": "Group Name", "max-length": 50, "form-item": "", "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("groupName", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 6 } }, [_c("date-picker", { staticClass: "w-100", attrs: { "value": _vm.entity.nextAuditDate, "format-date": _vm.DATE_FORMAT, "label": "Audit Due Date", "form-item": "", "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("nextAuditDate", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 6 } }, [_c("text-input", { attrs: { "value": _vm.entity.auditFrequency, "type": "number", "label": "Audit Frequency", "suffix": "month", "placeholder": "0", "form-item": "", "rules": "required|integer" }, on: { "change": function($event) {
      return _vm.storeValue("auditFrequency", $event);
    } } })], 1)], 1), _c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "xs": 24, "lg": 12 } }, [_c("text-area", { attrs: { "value": _vm.entity.comment, "label": "Comment", "rows": "4", "max-length": 500 }, on: { "change": function($event) {
      return _vm.storeValue("comment", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 6 } }, [_c("select-input", { key: "canSizeId", attrs: { "value": _vm.entity.canSizeId, "label": "Container Size", "lazy": "", "placeholder": "Select a Container size", "reference": "sterilization.common.can-sizes", "search-by": "Name", "source": "id", "source-label": "name", "source-description": "description", "form-item": "", "delete-filter": "IsInactive" }, on: { "change": function($event) {
      return _vm.storeValue("canSizeId", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 6 } }, [_c("select-input", { key: "steriliserId", attrs: { "value": _vm.entity.steriliserId, "label": "Steriliser", "lazy": "", "placeholder": "Select a steriliser", "reference": "sterilization.common.sterilisers", "search-by": "Description", "source": "id", "source-label": "description", "form-item": "", "delete-filter": "IsInactive" }, on: { "change": function($event) {
      return _vm.storeValue("steriliserId", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 24, "lg": 12 } }, [_c("text-input", { attrs: { "value": _vm.entity.nextAuditFillConditions, "label": "Next Audit Fill Condition", "max-length": 40 }, on: { "change": function($event) {
      return _vm.storeValue("nextAuditFillConditions", $event);
    } } })], 1)], 1), _c("a-row", [_c("a-col", { attrs: { "xs": 24 } }, [_vm.entity ? _c("p", [_vm._v(" Last modified by " + _vm._s(_vm.entity.updatedBy) + " on " + _vm._s(_vm._f("moment")(_vm.entity.updatedAt, _vm.DATE_TIME_FORMAT)) + " ")]) : _vm._e()])], 1)], 1)];
  } }]) })], 1);
};
var staticRenderFns$5 = [];
var InformationForm_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".information-group[data-v-126282c3] .ant-card-actions{background:#fff}\n")();
const __vue2_script$5 = {
  name: "InformationForm",
  inject: ["crud", "resourceProps", "can"],
  data() {
    const [groupProps] = this.resourceProps;
    return {
      DATE_FORMAT,
      DATE_TIME_FORMAT,
      groupProps,
      isLoading: false,
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  computed: {
    entity() {
      return this.groupProps.crud.getEntity() || {};
    }
  },
  methods: {
    storeValue(resourceKey, value) {
      if (!resourceKey.includes(",")) {
        this.crud.updateEntity("editForm", { resourceKey, value });
        return;
      }
      const resourceKeys = resourceKey.split(",");
      resourceKeys.forEach((key) => {
        this.groupProps.crud.updateEntity("editForm", {
          resourceKey: key,
          value: value[key]
        });
      });
    },
    async onSave() {
      this.isLoading = true;
      try {
        await this.groupProps.crud.submitEntity("replace");
      } finally {
        this.isLoading = false;
        this.$emit("updated");
      }
    },
    onCancel() {
      this.$router.push({
        name: "ListAuditGroups"
      });
    }
  }
};
const __cssModules$5 = {};
var __component__$5 = /* @__PURE__ */ normalizeComponent(__vue2_script$5, render$5, staticRenderFns$5, false, __vue2_injectStyles$5, "126282c3", null, null);
function __vue2_injectStyles$5(context) {
  for (let o in __cssModules$5) {
    this[o] = __cssModules$5[o];
  }
}
var InformationForm$1 = /* @__PURE__ */ function() {
  return __component__$5.exports;
}();
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-information-form" }, [_c("resource", { attrs: { "name": "sterilization.common.sterilisers", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.common.can-sizes", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.audit-groups", "api-url": _vm.apiUrl, "page": _vm.page } }, [_c("information-form", { on: { "updated": function($event) {
    return _vm.$emit("updated");
  } } })], 1)], 1);
};
var staticRenderFns$4 = [];
const apiUrl$3 = "#{VUE_APP_API_URL}#";
const __vue2_script$4 = {
  name: "EditInformationForm",
  components: {
    InformationForm: InformationForm$1
  },
  data() {
    return {
      InformationForm: InformationForm$1,
      apiUrl: apiUrl$3,
      page: STERILIZATION_AUDITS_AUDITGROUP
    };
  }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, null, null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var InformationForm = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-item-code py-5" }, [_c("a-row", [_c("a-col", { attrs: { "span": 18 } }, [_c("h5", { staticClass: "section-title" }, [_vm._v("Linked Item Code (" + _vm._s(_vm.numberOfItem) + ")")])]), _vm.can(_vm.permissions.maintain) ? _c("a-col", { staticClass: "text-right", attrs: { "span": 6 } }, [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.openAddLinkModal } }, [_vm._v("Add Link")])], 1) : _vm._e()], 1), _c("list-composer", { attrs: { "data-source": _vm.listItemCode, "has-active": false, "custom-padding-table": "", "custom-url": _vm.customUrl }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var record = ref.record;
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Break link")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.openConfirmation(record);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.goToDetail(record.id);
    } } })], 1)];
  } }]) }, [_c("url-field", { key: "ItemCode", attrs: { "base-url": "/sterilization-audits/audit-groups/" + _vm.groupId + "/item", "data-index": "itemCode", "title": "Item Code", "sorter": true } }), _c("text-field", { key: "Description", attrs: { "data-index": "itemDescription", "title": "Description", "width": 350 } }), _c("text-field", { key: "CanInkjetCode", attrs: { "data-index": "canInkjetCode", "title": "Can Inkjet Code", "sorter": true } }), _c("text-field", { key: "LastAuditDate", attrs: { "data-index": "lastAuditDate", "title": "Last Audit Date", "sorter": true, "parse": _vm.parseDate } }), _c("text-field", { key: "NoOfAudits", attrs: { "data-index": "noOfAudits", "title": "No Of Audits", "sorter": true } }), _c("text-field", { key: "NoOfDevStages", attrs: { "data-index": "noOfDevStages", "title": "No Of Dev Stages", "sorter": true } })], 1)], 1);
};
var staticRenderFns$3 = [];
var ListItemCode_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".section-title[data-v-46197898]{font-size:14px;color:#272d35;font-weight:500}.text-right[data-v-46197898]{text-align:right}.text-center[data-v-46197898]{text-align:center}\n")();
const apiUrl$2 = "#{VUE_APP_API_URL}#";
const __vue2_script$3 = {
  name: "ListItemCode",
  inject: ["crud", "can"],
  props: {
    groupId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      DATE_FORMAT,
      totalItems: 5,
      dataItemCode: [],
      visible: false,
      permissions: {
        maintain: MAINTAIN_PERMISSION
      },
      customUrl: `${apiUrl$2}/sterilization/audit-groups/${this.$route.params.id}/items`,
      columns: [
        {
          title: "Item Code",
          dataIndex: "itemCode",
          key: "ItemCode",
          scopedSlots: { customRender: "itemCode" },
          sorter: false
        },
        {
          title: "Description",
          dataIndex: "itemDescription",
          key: "ItemDescription"
        },
        {
          title: "Can Inkjet Code",
          dataIndex: "canInkjetCode",
          key: "CanInkjetCode",
          sorter: false
        },
        {
          title: "Last Audit Date",
          dataIndex: "lastAuditDate",
          key: "LastAuditDate",
          sorter: false,
          scopedSlots: { customRender: "lastAuditDate" }
        },
        {
          title: "No of Audits",
          dataIndex: "noOfAudits",
          key: "NoOfAudits",
          align: "right",
          sorter: false
        },
        {
          title: "No of Dev Stage",
          dataIndex: "noOfDevStages",
          key: "NoOfDevStages",
          align: "right",
          sorter: false
        },
        {
          title: "Action",
          dataIndex: "action",
          align: "center",
          scopedSlots: { customRender: "action" }
        }
      ]
    };
  },
  computed: {
    listItemCode() {
      return this.crud.getList();
    },
    numberOfItem() {
      return this.crud.getPagination().count || 0;
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.crud.fetchList(`${apiUrl$2}/sterilization/audit-groups/${this.$route.params.id}/items`);
    },
    parseDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format(DATE_FORMAT);
    },
    openAddLinkModal() {
      this.$router.push(`/sterilization-audits/audit-groups/${this.groupId}/create-link`);
    },
    openConfirmation(record) {
      let config;
      const { id, itemCode } = record;
      if (record.noOfAudits > 0) {
        config = {
          class: "list__modal-delete",
          title: "Can not break this link",
          content: "You are not allowed to break this link since " + itemCode + " has its audit record."
        };
        this.$warning(config);
      } else {
        config = {
          class: "list__modal-delete",
          icon: "delete",
          title: "Do you want to break link " + itemCode + "?",
          content: itemCode + " is linked to this group. Do you want to break this link?",
          cancelText: "Cancel",
          okButtonProps: { props: { type: "danger" } },
          okText: "Break Link",
          okType: "error",
          onOk: this.breakLink(id, itemCode)
        };
        this.$confirm(config);
      }
    },
    breakLink(itemId, itemCode) {
      const customUrl = `${apiUrl$2}/sterilization/audit-groups/${this.groupId}/items/${itemCode.trim()}`;
      return () => this.crud.destroyEntity(customUrl, itemId).then(() => {
        this.getData();
      });
    },
    goToDetail(recordId) {
      this.$router.push(`/sterilization-audits/audit-groups/${this.groupId}/item/${recordId}`);
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, "46197898", null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var ListItemCode = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-item-code-container" }, [_c("resource", { attrs: { "name": "sterilization.audit-groups.items.list", "api-url": _vm.apiUrl, "page": _vm.page } }, [_c("list-item-code", { attrs: { "group-id": _vm.groupId } })], 1)], 1);
};
var staticRenderFns$2 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  name: "ListItemCodeContainer",
  components: {
    ListItemCode
  },
  props: {
    groupId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      apiUrl: apiUrl$1,
      page: STERILIZATION_AUDITS_AUDITGROUP
    };
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ListItemCodeContainer = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-audit-group" }, [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("page-header", { attrs: { "title": _vm.entity.groupName, "back": _vm.backTo, "has-tag": _vm.entity.status } }, [_c("template", { slot: "tag-content" }, [_c("tag-field", { attrs: { "value": _vm.AUDIT_STATUS_TEXTS[_vm.entity.status], "color": _vm.AUDIT_STATUS_COLORS[_vm.entity.status] } })], 1)], 2), _c("a-tabs", { attrs: { "default-active-key": "generalInformation" } }, [_c("a-tab-pane", { key: "generalInformation", attrs: { "tab": "General Information" } }, [_c("div", { staticClass: "px-5 py-3" }, [_c("information-form"), _c("list-item-code-container", { attrs: { "group-id": _vm.entity.id } })], 1)]), _c("a-tab-pane", { key: "auditCycle", attrs: { "tab": "Audit Cycle" } }, [_c("list-audit-cycle", { attrs: { "group-id": _vm.entity.id || _vm.$route.params.id } })], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var EditAuditGroup_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".edit-audit-group[data-v-ed28312a] .ant-tabs-bar{background-color:#fff}\n")();
const __vue2_script$1 = {
  name: "EditAuditGroup",
  inject: ["resourceProps"],
  components: {
    ListAuditCycle,
    InformationForm,
    ListItemCodeContainer
  },
  data() {
    const [groupProps, groupItemProps] = this.resourceProps;
    return {
      groupProps,
      groupItemProps,
      preItems: [
        {
          title: "Data Entry",
          path: ""
        },
        {
          title: "Audit Group",
          path: "/sterilization-audits/audit-groups"
        }
      ],
      AUDIT_STATUS_COLORS,
      AUDIT_STATUS_TEXTS
    };
  },
  computed: {
    entity() {
      return this.groupProps.crud.getEntity() || {};
    },
    menuItems() {
      return [
        ...this.preItems,
        {
          title: this.entity.groupName,
          path: `/sterilization-audits/audit-groups/${this.entity.id}`
        }
      ];
    }
  },
  watch: {
    entity() {
      Object.keys(this.entity).forEach((key) => {
        this.groupProps.crud.updateEntity("editForm", {
          resourceKey: key,
          value: this.entity[key]
        });
      });
    }
  },
  async created() {
    await this.groupProps.crud.fetchEntity();
  },
  methods: {
    backTo() {
      this.$router.push({
        name: "ListAuditGroups"
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "ed28312a", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditAuditGroup = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-audit-group" }, [_c("resource", { attrs: { "name": ["sterilization.audit-groups", "sterilization.audit-groups.items"], "api-url": _vm.apiUrl, "redirect-route": ["/sterilization-audits/audit-groups"] } }, [_c("edit-audit-group")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditAuditGroupIndex",
  components: {
    EditAuditGroup
  },
  data() {
    return {
      apiUrl
    };
  },
  provide() {
    return {
      groupId: this.$route.params.id
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
